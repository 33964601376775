import "./Menu.css";
import DOI from "../../assets/imgs/doi.png";
function Menu() {
  return (
    <div className="menuContainer py-4">
      <div className="container">
        <div className="row">
          <div className="col-4 d-flex text-center">
              <input id="test" type="checkbox" />
              <label htmlFor="test">
                {/* <svg className="burger" width="86" height="60" viewbox="0 0 150 150">
                  <g stroke-width="12">
                    <line x1="6" y1="6" x2="80" y2="6"></line>
                    <line x1="6" y1="28" x2="80" y2="28"></line>
                    <line x1="6" y1="50" x2="80" y2="50"> </line>
                  </g>
                </svg>
                <svg className="close" width="86" height="60" viewbox="0 0 150 150">
                  <g stroke-width="12">
                    <line x1="42" y1="6" x2="42" y2="80"></line>
                    <line x1="6" y1="42" x2="80" y2="42"></line>
                  </g>
                </svg> */}
                <a className="burger"><i className="fa fa-bars barsIcon"></i></a>
                <a className="close"><i className="fa fa-times barsIcon"></i></a>
              </label>
              <ul className="menu">
                <li><a href="#">Home</a> </li>
                <li><a href="#">About</a> </li>
                <li><a href="#">How it works</a> </li>
                <li><a href="#">Blog</a> </li>
                <li><a href="#">FAQ</a> </li>
                <li><a href="#">Contact Us</a> </li>
                <li className="showInMob"> <button className="downloadButton">Download Wallet</button></li>
              </ul>
          </div>
          <div className="col-4 text-center d-flex">
            {/* <img className="m-auto" src={DOI} alt="DOI" /> */}
          </div>
          <div className="col-4 text-end position-relative hideInMob">
            <button className="downloadButton">Download Wallet</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
