import "./Header.css";
import doi from "../../assets/imgs/logo.png";

function Header() {
  return (
    <div className="header">
      <div className="container m-auto">
        <div className="row justify-content-center py-5">
          <div className="col-md-6 text-center pb-5">
            <img src={doi} alt="doi_circles" className="circles" />
            <p>Property Tokenization. Using Nft's in Real Estate</p>
            <h3>Hotels, Apartments, Villas, Houses and more</h3>
            <div className="position-relative">
              <input type="text" placeholder="Search Your Dream Property..." />
              <a className="searchBtn">
                <i className="fa fa-search"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
