import "./WhyDoi.css";
import first from "../../assets/imgs/whydoi/1.png";
import second from "../../assets/imgs/whydoi/2.png";
import third from "../../assets/imgs/whydoi/3.png";
import fourth from "../../assets/imgs/whydoi/4.png";
import fifth from "../../assets/imgs/whydoi/5.png";
import sixth from "../../assets/imgs/whydoi/6.png";

function WhyDoi() {
  return (
    <div className="whyDoi py-5">
      <div className="container">
        <h2 className="text-center text-white pt-5 mb-4">Why Doi?</h2>
        <div className="row justify-content-center pb-3">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-4 text-center mb-3">
                <img src={first} alt="logo" />
                <h4>Competitive Fees</h4>
                <p>textttttt</p>
              </div>
              <div className="col-md-4 text-center mb-3">
                <img src={second} alt="logo" />
                <h4>Non-trust based System</h4>
                <p>textttttt</p>
              </div>
              <div className="col-md-4 text-center mb-3">
                <img src={third} alt="logo" />
                <h4>multi-chain capability</h4>
                <p>textttttt</p>
              </div>
              <div className="col-md-4 text-center mb-3">
                <img src={fourth} alt="logo" />
                <h4>Transparent transactions</h4>
                <p>textttttt</p>
              </div>
              <div className="col-md-4 text-center mb-3">
                <img src={fifth} alt="logo" />
                <h4>User Friendly WebApp</h4>
                <p>textttttt</p>
              </div>
              <div className="col-md-4 text-center mb-3">
                <img src={sixth} alt="logo" />
                <h4>Natural Scalability</h4>
                <p>textttttt</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyDoi;
