import "./Footer.css";
import Logo from "../../assets/imgs/logo.png";
import insta from "../../assets/imgs/instagram.png";
import twitter from "../../assets/imgs/twitter.png";

function Footer() {
  return (
    <>
      <div className="footer">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10">
              <div className="row">
                <div className="col-md-4">
                  <img src={Logo} alt="logo" className="footerLogo d-block m-auto" />
                  <p className="text-white p-3 text-center">
                    DOI tokenize real world assets into NFTs that can be used as
                    financial digital assests
                  </p>
                </div>
                <div className="col-md-4 d-flex mt-3">
                  <div className="mx-auto">
                    <ul>
                      <li>
                        <b>Quick Links</b>
                      </li>
                      <li>
                        <a href="#">Home</a>
                      </li>
                      <li>
                        <a href="#">My Collection</a>
                      </li>
                      <li>
                        <a href="#">White Pager</a>
                      </li>
                      <li>
                        <a href="#">Tokenomics</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 d-flex mt-3">
                  <div className="mx-auto">
                    <a target="_blank"
                      href="https://www.instagram.com/digital_oro_international/?hl=es">
                      <img className="socialPics me-4" src={insta} alt="insta" />
                    </a>
                    <a target="_blank" href="https://twitter.com/NFT_DOI">
                      <img className="socialPics" src={twitter} alt="twitter" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright py-3 text-center">
          <p>Copyright © 2022 DOI - All Rights Reserved</p>
        </div>
      </div>
    </>
  );
}

export default Footer;
