import "./LatestListings.css";
import propertyImg from "../../assets/imgs/property/1.jpg";

import bed from "../../assets/imgs/icons/bed.png";
import bathroom from "../../assets/imgs/icons/bathroom.png";
import car from "../../assets/imgs/icons/car.png";

const propertiesList = [
  {
    id: 1,
    price: "ED 10.5k/monthly",
    image: propertyImg,
    address: "3300 Avenida Reforma",
    bed: 2,
    bathroom: 3,
    car: 4,
    area: "2000",
  },
  {
    id: 2,
    price: "ED 10.5k/monthly",
    image: propertyImg,
    address: "3300 Avenida Reforma",
    bed: 2,
    bathroom: 3,
    car: 4,
    area: "2000",
  },
  {
    id: 3,
    price: "ED 10.5k/monthly",
    image: propertyImg,
    address: "3300 Avenida Reforma",
    bed: 2,
    bathroom: 3,
    car: 4,
    area: "2000",
  },
  {
    id: 4,
    price: "ED 10.5k/monthly",
    image: propertyImg,
    address: "3300 Avenida Reforma",
    bed: 2,
    bathroom: 3,
    car: 4,
    area: "2000",
  },
  {
    id: 5,
    price: "ED 10.5k/monthly",
    image: propertyImg,
    address: "3300 Avenida Reforma",
    bed: 2,
    bathroom: 3,
    car: 4,
    area: "2000",
  },
];

function LatestListings() {
  return (
    <div className="latest_listings py-5">
      <div className="container">
        <h3 className="text-center pt-5 mb-4 fw-bold">Latest Listings</h3>
        <div className="row justify-content-center">
          <div className="col-md-10">
            <div className="row">
              {propertiesList.map((property) => (
                <div className="col-md-4" key={property.id}>
                  <div className="propertyItem mb-4">
                    <img className="propertyImg" src={property.image} alt="logo" />
                    <div className="propertyDesc">
                      <h4>{property.price}</h4>
                      <p>{property.address}</p>
                      <div className="d-flex justify-content-between">
                        <p className="detail">
                          <img src={bed} alt="logo" />

                          <span>{property.bed}</span>
                        </p>
                        <p className="detail">
                          <img src={bathroom} alt="logo" />

                          <span>{property.bathroom}</span>
                        </p>
                        <p className="detail">
                          <img src={car} alt="logo" />

                          <span>{property.car}</span>
                        </p>
                        <p className="areaDetail">
                          sqft <span>{property.area}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LatestListings;
