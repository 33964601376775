import Home from "./Home/Home";
import "./assets/css/all.min.css";
import "./assets/css/bootstrap.min.css";
// import PropertyDetails from "./PropertyDetails/PropertyDetails";

function App() {
  return (
    <div className="App">
      <Home />
    {/* <PropertyDetails /> */}
    </div>
  );
}

export default App;
