import './Home.css';
import Menu from "../shared/Menu/Menu";
import Footer from "../shared/Footer/Footer";
import Header from "./Header/Header";
import LatestListings from "./LatestListings/LatestListings";
import WhyDoi from "./WhyDoi/WhyDoi";

function Home() {
  return (
    <>
      <div className='homeMenu'>
        <Menu />
      </div>
      <div className='homeBg'>
        <Header />
        <LatestListings />
      </div>
      <WhyDoi />
      <Footer />
    </>
  );
}

export default Home;
